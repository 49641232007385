export const authActionTypes = {
	// Initiate fetching
	SIGN_UP_START: 'SIGN_UP_START',
	SEND_OTP_START: 'SEND_OTP_START',
	SEND_RESET_PASSWORD_OTP_START: 'SEND_RESET_PASSWORD_OTP_START',
	VALIDATE_OTP_START: 'VALIDATE_OTP_START',
	RESET_MY_PASSWORD_START: 'RESET_MY_PASSWORD_START',
	SIGN_IN_START: 'SIGN_IN_START',
	FETCH_USER_PROFILE: 'FETCH_USER_PROFILE',
	CHANGE_PROFILE_PICTURE: 'CHANGE_PROFILE_PICTURE',
	CHANGE_PROFILE_INFO: 'CHANGE_PROFILE_INFO',
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	SIGN_OUT: 'SIGN_OUT',
	// set values
	SET_USER: 'SET_USER',
	SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
	// Toggle loading
	TOGGLE_SIGN_UP_LOADING: 'TOGGLE_SIGN_UP_LOADING',
	TOGGLE_SEND_OTP_LOADING: 'TOGGLE_SEND_OTP_LOADING',
	TOGGLE_VALIDATE_OTP_LOADING: 'TOGGLE_VALIDATE_OTP_LOADING',
	TOGGLE_RESET_MY_PASSWORD_LOADING: 'TOGGLE_RESET_MY_PASSWORD_LOADING',
	TOGGLE_SIGN_IN_LOADING: 'TOGGLE_SIGN_IN_LOADING',
	TOGGLE_USER_PROFILE_LOADING: 'TOGGLE_USER_PROFILE_LOADING',
	TOGGLE_CHANGE_PROFILE_PICTURE_LOADING:
		'TOGGLE_CHANGE_PROFILE_PICTURE_LOADING',
	TOGGLE_CHANGE_PROFILE_INFO_LOADING: 'TOGGLE_CHANGE_PROFILE_INFO_LOADING',
	TOGGLE_SEND_RESET_PASSWORD_OTP_LOADING:
		'TOGGLE_SEND_RESET_PASSWORD_OTP_LOADING',
	TOGGLE_CHANGE_PROFILE_INFO_LOADING: 'TOGGLE_CHANGE_PROFILE_INFO_LOADING',

	TOGGLE_CHANGE_PASSWORD_LOADING: 'TOGGLE_CHANGE_PASSWORD_LOADING',
	// Set errors
	SIGNIN_ERROR: 'SIGNIN_ERROR',
};

const path = '/assets/static';

export const admin = `${path}/admin.svg`;
export const profile = `${path}/profile.png`;
export const agent = `${path}/agent.svg`;
export const area = `${path}/area.svg`;
export const badge = `${path}/badge.svg`;
export const bag = `${path}/bag.svg`;
export const bed = `${path}/bed.svg`;
export const bookmark = `${path}/bookmark.svg`;
export const byBHK = `${path}/byBHK.svg`;
export const byType = `${path}/byType.svg`;
export const byUnit = `${path}/byUnit.svg`;
export const call = `${path}/call.svg`;
export const car = `${path}/car.svg`;
export const comment = `${path}/comment.svg`;
export const fingerLike = `${path}/fingerLike.svg`;
export const like = `${path}/like.svg`;
export const loan = `${path}/loan.svg`;
export const location = `${path}/location.svg`;
export const location2 = `${path}/location2.svg`;
export const logo = `${path}/logo.svg`;
export const menu = `${path}/menu.svg`;
export const news = `${path}/news.svg`;
export const rocket = `${path}/rocket.svg`;
export const tag = `${path}/tag2.svg`;
export const tub = `${path}/tub.svg`;
export const userComment = `${path}/userComment.svg`;
export const valuation = `${path}/valuation.svg`;
export const whatsapp = `${path}/whatsapp.svg`;
export const whatsappOutline = `${path}/whatsappOutline.svg`;

export const propertyActionTypes = {
	// Initiate fetching
	SEARCH_PROPERTY_START: 'SEARCH_PROPERTY_START',
	GET_PROPERTY_DETAILS_START: 'GET_PROPERTY_DETAILS_START',
	GET_PROJECT_DETAILS_START: 'GET_PROJECT_DETAILS_START',
	GET_PROJECT_PROPERTY_DETAILS_START: 'GET_PROJECT_PROPERTY_DETAILS_START',
	GET_PROPERTY_RESOURCES_START: 'GET_PROPERTY_RESOURCES_START',
	GET_PROPERTY_COUNT_START: 'GET_PROPERTY_COUNT_START',
	POST_PROPERTY_START: 'POST_PROPERTY_START',
	UPDATE_PROPERTY_START: 'UPDATE_PROPERTY_START',
	GET_MY_PROPERTIES_START: 'GET_MY_PROPERTIES_START',
	QUERY_ON_PROPERTY_START: 'QUERY_ON_PROPERTY_START',
	GET_QUERIES: 'GET_QUERIES',
	// set values
	SET_PROPERTY_RESOURCES: 'SET_PROPERTY_RESOURCES',
	SET_MY_QUERIES: 'SET_MY_QUERIES',
	SET_QUERIES_RECEIVED: 'SET_QUERIES_RECEIVED',
	SET_PROPERTIES_COUNT: 'SET_PROPERTIES_COUNT',
	// Toggle loading
	TOGGLE_SEARCH_PROPERTY_LOADING: 'TOGGLE_SEARCH_PROPERTY_LOADING',
	TOGGLE_GET_PROPERTY_DETAILS_LOADING: 'TOGGLE_GET_PROPERTY_DETAILS_LOADING',
	TOGGLE_GET_PROJECT_DETAILS_LOADING: 'TOGGLE_GET_PROJECT_DETAILS_LOADING',
	TOGGLE_GET_PROJECT_PROPERTY_DETAILS_LOADING:
		'TOGGLE_GET_PROJECT_PROPERTY_DETAILS_LOADING',
	TOGGLE_GET_PROPERTY_RESOURCES_LOADING:
		'TOGGLE_GET_PROPERTY_RESOURCES_LOADING',
	TOGGLE_GET_PROPERTY_COUNT_LOADING: 'TOGGLE_GET_PROPERTY_COUNT_LOADING',
	TOGGLE_POST_PROPERTY_LOADING: 'TOGGLE_POST_PROPERTY_LOADING',
	TOGGLE_UPDATE_PROPERTY_LOADING: 'TOGGLE_UPDATE_PROPERTY_LOADING',
	TOGGLE_MY_PROPERTIES_LOADING: 'TOGGLE_MY_PROPERTIES_LOADING',
	TOGGLE_QUERY_ON_PROPERTY_LOADING: 'TOGGLE_QUERY_ON_PROPERTY_LOADING',
	TOGGLE_GET_QUERIES_LOADING: 'TOGGLE_GET_QUERIES_LOADING',
	// SetErrors
};

export const cityActionTypes = {
	// Initiate fetching
	SEARCH_CITY_START: 'SEARCH_CITY_START',
	SEARCH_LOCATION_START: 'SEARCH_LOCATION_START',
	// set values
	SET_SELECTED_CITY: 'SET_SELECTED_CITY',
	// Toggle loading
	TOGGLE_SEARCH_CITY_LOADING: 'TOGGLE_SEARCH_CITY_LOADING',
	TOGGLE_SEARCH_LOCATION_LOADING: 'TOGGLE_SEARCH_LOCATION_LOADING',
	// SetErrors
};

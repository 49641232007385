export const contactActionTypes = {
	// Initiate fetching
	ADD_CONTACT_START: 'ADD_CONTACT_START',
	VALIDATE_CONTACT_NUMBER_START: 'VALIDATE_CONTACT_NUMBER_START',
	// set values
	// Toggle loading
	TOGGLE_ADD_CONTACT_LOADING: 'TOGGLE_ADD_CONTACT_LOADING',
	TOGGLE_VALIDATE_CONTACT_NUMBER_LOADING:
		'TOGGLE_VALIDATE_CONTACT_NUMBER_LOADING',
	// SetErrors
};

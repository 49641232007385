export const actionTabActionTypes = {
	// Initiate fetching

	// set values
	SET_CURRENT_TAB: 'SET_CURRENT_TAB',
	SET_SELECTED_CITIES: 'SET_SELECTED_CITIES',
	// Toggle loading

	// SetErrors
};
